var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "sx-card", attrs: { elevation: _vm.elevation } },
    [
      _vm.title
        ? _c("v-card-title", [_vm._v(" " + _vm._s(_vm.title) + " ")])
        : _vm._e(),
      _c(
        "v-card-text",
        { staticClass: "sx-card-text" },
        [_vm._t("default")],
        2
      ),
      _c("v-card-actions", [_vm._t("actions")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }