<template>
    <v-card class="sx-card" :elevation="elevation">
        <v-card-title v-if="title">
            {{title}}
        </v-card-title>
        <v-card-text class="sx-card-text">
            <slot></slot>
        </v-card-text>
        <v-card-actions>
            <slot name="actions">
            </slot>
        </v-card-actions>
    </v-card> 
</template>

<script>
export default {
    name: 'sx-card',
    props: {
        title: {
            type: String
        },
        elevation: {
            type: [String, Number],
            default: 1
        }
    }
}
</script>

<style lang="sass">

.sx-center > .sx-card
    margin-bottom: 25px !important

.sx-card-text
    margin-top: 15px
    
</style>